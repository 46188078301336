import axios from 'axios';

import APIS from '@/constants/apis-constant';

const configurationsApi = {
  // requestUserForms({ token, configuration_id, user_id }) {
  requestUserForms({ configuration_id, user_id }) {
    const headers = {
      // Authorization: `Bearer ${token}`,
    };
    return axios({
      url: `/${configuration_id}/user/${user_id}/forms`,
      method: 'get',
      headers,
      baseURL: APIS.FOX_CONFIGURATIONS,
    });
  },
  // requestUserFormsById({ token,configuration_id, user_id, form_id }){
  requestUserFormsById({ configuration_id, user_id, form_id, params }) {
    const headers = {
      // Authorization: `Bearer ${token}`,
    };
    return axios({
      url: `/${configuration_id}/user/${user_id}/forms/${form_id}/`,
      method: 'get',
      headers,
      baseURL: APIS.FOX_CONFIGURATIONS,
      params,
      responseType: 'arraybuffer',
    });
  },
};

export default configurationsApi;
