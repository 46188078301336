import axios from 'axios';

import { APIS } from '@/constants';

const keycloakApi = {
  requestUsersList(token, params) {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    return axios({
      method: 'get',
      baseURL: APIS.KEYCLOAK_USERS,
      headers,
      params,
    });
  },
  requestUserDetail(token, id) {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    return axios({
      url: `/${id}`,
      method: 'get',
      baseURL: APIS.KEYCLOAK_USERS,
      headers,
    });
  },
  requestUserEvents({ token, id, type }) {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const params = {
      user: id,
      type,
    };
    return axios({
      method: 'get',
      baseURL: APIS.KEYCLOAK_EVENTS,
      headers,
      params,
    });
  },
};

export default keycloakApi;
