import axios from 'axios';

import APIS from '@/constants/apis-constant';

const filesApi = {
  // requestAsset({ configuration_id, data }, token) {
  requestAsset({ configuration_id, body }) {
    const headers = {
      // Authorization: `Bearer ${token}`,
    };
    return axios({
      url: `/${configuration_id}/download`,
      method: 'post',
      headers,
      baseURL: APIS.FOX_FILES,
      data: body,
      responseType: 'arraybuffer',
    });
  },
};

export default filesApi;
