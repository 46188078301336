import { configurationsApi, filesApi, keycloakApi } from '@/apis';
import { CONFIG, KEYCLOAK_CONFIG } from '@/constants';

export default {
  async initAccountsList({ commit, dispatch }, filter) {
    commit('reset');
    commit('setLoading', true);
    const requests = [dispatch('fetchAccountsList', filter)];
    await Promise.all(requests);
    commit('setLoading', false);
  },
  async fetchAccountsList({ commit, rootGetters }, filter) {
    const token = rootGetters['token'];
    const params = filter || {};
    try {
      const response = await keycloakApi.requestUsersList(token, params);
      commit('setList', response.data);
    } catch (error) {
      commit('setError', error);
    }
  },
  async initAccountsDetail({ commit, dispatch }, accountId) {
    commit('reset');
    commit('setLoading', true);
    const requests = [
      dispatch('fetchAccountDetail', accountId),
      dispatch('fetchAccountForms', accountId),
    ];
    await Promise.all(requests);
    commit('setLoading', false);
  },
  async fetchAccountDetail({ commit, rootGetters }, accountId) {
    const token = rootGetters['token'];
    try {
      const detail = await keycloakApi.requestUserDetail(token, accountId);
      const events = await keycloakApi.requestUserEvents({
        token,
        id: accountId,
        type: KEYCLOAK_CONFIG.EVENT_TYPE.LOGIN,
      });
      const account = Object.assign(
        {},
        { ...detail.data },
        { events: events.data },
      );
      commit('setDetail', account);
    } catch (error) {
      commit('setError', error);
    }
  },
  async fetchAccountForms({ commit, rootGetters }, accountId) {
    const token = rootGetters['token'];
    try {
      const response = await configurationsApi.requestUserForms({
        token,
        configuration_id: CONFIG.ID,
        user_id: accountId,
      });
      commit('setForms', response.data.list);
    } catch (error) {
      commit('setError', error);
    }
  },
  async fetchQuizPdf({ commit, rootGetters }, accountId) {
    const token = rootGetters['token'];
    let asset;
    commit('setFetchingAsset', true);
    try {
      const response = await configurationsApi.requestUserFormsById({
        token,
        configuration_id: CONFIG.ID,
        user_id: accountId,
        form_id: CONFIG.MODEL.ID.AML,
        params: { pdf: true },
      });
      asset = response.data;
    } catch (error) {
      asset = null;
    } finally {
      commit('setFetchingAsset', false);
    }
    return asset;
  },
  async fetchAsset({ commit, dispatch }, { accountId, assetId }) {
    commit('setFetchingAsset', true);
    const asset = await dispatch('getAsset', { accountId, assetId });
    commit('setFetchingAsset', false);
    return asset;
  },
  async getAsset({ rootGetters }, { accountId, assetId }) {
    const token = rootGetters['token'];
    const body = {
      uri: `users/documents/${CONFIG.ID}/${accountId}-${assetId}`,
    };
    let asset;
    try {
      const response = await filesApi.requestAsset({
        token,
        configuration_id: CONFIG.ID,
        body,
      });
      asset = response.data;
    } catch (error) {
      asset = null;
    }
    return asset;
  },
};
